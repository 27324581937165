import React from 'react';
import Box from 'components/Box';
import styled from 'styled-components';
import { rem } from 'polished';
import { breakpoints } from 'theme';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import CareersGroupIcon from 'images/CareersGroupIcon.png';

const BenefitsWrapper = styled.div`
  background-color: ${(props) => props.theme.brandPalette.primary};
`;

const EachCard = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  gap: 16px;
  border-radius: ${rem(10)};
  background-color: white;
  margin: ${rem(16)};
  justify-content: center;
  align-items: center;
  padding: 24px 40px 40px;
  width: 349px;
  height: 328px;

  img {
    width: ${rem(80)};
  }

  h6 {
    font-weight:  ${(props) => props.theme.fontWeights.semibold};
    font-size: ${rem(20)};
    line-height: ${rem(32)};
    text-align: center;
  }

  p {
    font-weight: ${(props) => props.theme.fontWeights.regular};
    font-size: ${rem(16)};
    line-height: ${rem(24)};
    color: ${(props) => props.theme.brandPalette.dark};
    text-align: center;
  }

  @media (max-width: ${breakpoints.tablet}) {
    width: ${rem(327)};
    height: ${rem(278)};

    img {
      width: ${rem(49)};
      margin-top: ${rem(34)};
    }

    h5 {
      font-weight: ${(props) => props.theme.fontWeights.semibold};
      font-size: ${rem(18)};
      line-height: ${rem(22)};
      margin-top: ${rem(5)};
    }
    p {
      font-weight: ${(props) => props.theme.fontWeights.regular};
      font-size: ${rem(16)};
      line-height: ${rem(24)};
      margin-bottom: 24px;
    }
  }
`;

const BenefitsHeadings = styled.div`
  text-align: center;
  color: ${(props) => props.theme.neutralPalette.white};


  a {
    cursor: pointer;
  }

  h6 {
    font-size: ${rem(20)};
    font-weight: ${(props) => props.theme.fontWeights.light};
    line-height: ${rem(32)};
  }

  h3 {
    font-size: ${rem(32)};
    font-weight: ${(props) => props.theme.fontWeights.semibold};
    line-height: ${rem(46)};
    margin-bottom: ${rem(80)};
  }


  @media (max-width: ${breakpoints.tablet}) {
    text-align: left;
    margin-bottom: 39px;
    margin-top: 70px;

    h6 {
      font-size: 18px;
      line-height: 22px;
      font-weight: 300;
    }
    h3 {
      font-size: 28px;
      line-height: 32px;
      font-weight: 600;
      margin-top: 1px;
    }
  }
`;

const CardBox = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  @media (max-width: ${breakpoints.tablet}) {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    margin-bottom: 40px;
  }
`;

const CARDS_BENEFITS = [
  {
    icon: CareersGroupIcon,
    heading: 'careers.why_join.environment.title',
    text: 'careers.why_join.environment.subtitle',
  },
  {
    icon: CareersGroupIcon,
    heading: 'careers.why_join.growth.title',
    text: 'careers.why_join.growth.subtitle',
  },
  {
    icon: CareersGroupIcon,
    heading: 'careers.why_join.expertise.title',
    text: 'careers.why_join.expertise.subtitle',
  },
];

interface CardProps {
  element: {
    icon: any;
    heading: string;
    text: string;
  };
}

const Cards = ({ element }: CardProps) => {
  const { icon, heading, text } = element;
  const { t } = useTranslation();

  return (
    <>
      <EachCard>
        <img src={icon} alt="" aria-hidden="true" />
        <h6>{t(heading)}</h6>
        <p>{t(text)}</p>
      </EachCard>
    </>
  );
};

const Benefits = () => {
  const { t } = useTranslation();

  return (
    <BenefitsWrapper>
      <Box>
        <BenefitsHeadings>
          <h6> {t('careers.why_join.title')} </h6>
          <h3> {t('careers.why_join.subtitle')} </h3>
        </BenefitsHeadings>
        <CardBox>
          {CARDS_BENEFITS.map((item) => (
            <Cards element={item} key={item.text} />
          ))}
        </CardBox>
      </Box>
    </BenefitsWrapper>
  );
};

export default Benefits;
