const getGoogleSheetsEnv = (lng: string) => {
  switch (lng) {
    case 'es':
      return process.env.GATSBY_GOOGLE_SHEETS_ID_ES;
    case 'pt':
      return process.env.GATSBY_GOOGLE_SHEETS_ID_PT;
    case 'it':
      return process.env.GATSBY_GOOGLE_SHEETS_ID_IT;
    default:
      return process.env.GATSBY_GOOGLE_SHEETS_ID_ES;
  }
};

export default getGoogleSheetsEnv;
