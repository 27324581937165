import React from 'react';
import Careers from 'pageComponents/Careers';
import { graphql, HeadProps, navigate } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';

const CareersPage = () => {
  const { language } = useI18next();

  const isBrowser = typeof window !== 'undefined';

  if (isBrowser && language === 'en') return navigate('/');

  return <Careers />;
};

export default CareersPage;

export function Head(props: HeadProps) {
  const { t } = useI18next();
  return (
    <title>{t('home.atf.navigation_tabs.careers')} | Safebrok</title>
  );
}
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
