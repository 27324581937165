import React from 'react';
import type { HeadFC } from 'gatsby';
import Offers from './components/Offers';
import PageHero from 'components/PageHero';
import Benefits from './components/Benefits';

const Careers = () => {
  return (
    <>
      <PageHero />
      <Offers />
      <Benefits />
    </>
  );
};

export default Careers;

export const Head: HeadFC = () => <title>Careers</title>;
