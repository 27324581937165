import React, { useEffect, useState } from 'react';
import * as Styles from '../styles';
import slugify from 'utils/slugify';

import PathFilters from 'images/PathFilters.png';
import CloseChips from 'images/CloseChips.png';
import NoOffers from 'images/noOffers.svg';

import { Trans, Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { fetchGoogleSheetsData } from 'google-sheets-mapper';

import SearchBar from 'components/SearchBar';
import Dropdown from 'components/Dropdown';
import LoadingSpinner from 'components/LoadingSpinner';
import NoLinkButton from 'components/NoLinkButton';
import DropdownRadio from 'components/MultiDropdown';
import getGoogleSheetsEnv from 'utils/getGoogleSheetsEnv';
import ListEmptyState from 'components/ListEmptyState';

interface Offer {
  description: string;
  id: string;
  languages: string;
  link: string;
  location: string;
  requirements: string;
  responsibilities: string;
  roleTitle: string;
  skills: string;
  team: string;
}

interface Offers {
  offers: Offer[];
  loading: boolean;
  error?: string | null;
}

const OffersList = ({ offers, loading, error }: Offers) => {
  const { t } = useTranslation();

  if (loading) {
    return (
      <Styles.ErrorAndLoading>
        <LoadingSpinner />
      </Styles.ErrorAndLoading>
    );
  }

  if (error) {
    return (
      <Styles.ErrorAndLoading>
        <h1>Error!</h1>
      </Styles.ErrorAndLoading>
    );
  }

  if (!loading && offers.length === 0) {
    return (
      <ListEmptyState
        image={NoOffers}
        title={'careers_empty_title'}
        subtitle={'careers_empty_body'}
      />
    );
  }

  return (
    <>
      <Styles.SpanListings>
        <Styles.RoleSpan>{t('careers.openings.position')}</Styles.RoleSpan>
        <Styles.ProfessionSpan>
          {t('careers.openings.team')}
        </Styles.ProfessionSpan>
        <Styles.LocationSpan>
          {t('careers.openings.location')}
        </Styles.LocationSpan>
      </Styles.SpanListings>
      <Styles.RolesContainer>
        {offers.map((offer) => (
          <Styles.OffersContainer key={`${offer.id}-${offer.location}-web`}>
            <Styles.Role>
              <h6>{offer.roleTitle}</h6>
            </Styles.Role>
            <Styles.Team>
              <h6>{offer.team}</h6>
            </Styles.Team>
            <Styles.Location>
              <h6>{offer.location.split(',')[0]}</h6>
            </Styles.Location>
            <Link
              className="apply-link"
              to={`/careers/${slugify(offer.roleTitle)}-${slugify(
                offer.location,
              )}`}
            >
              {t('careers.openings.apply')}
            </Link>
          </Styles.OffersContainer>
        ))}
      </Styles.RolesContainer>
      <Styles.RolesMobile>
        {offers.map((offer) => (
          <Styles.OffersContainerMob key={`${offer.id}-${offer.location}-web`}>
            <Styles.Role>
              <h6 className="RoleTitleMob">{offer.roleTitle.split('  ')}</h6>
              <Link
                className="apply-link"
                to={`/careers/${slugify(offer.roleTitle)}-${slugify(
                  offer.location,
                )}`}
              >
                {t('careers.openings.apply')}
              </Link>
            </Styles.Role>
            <Styles.Team>
              {t('careers.openings.team')}
              <h6>{offer.team}</h6>
            </Styles.Team>
            <Styles.Location>
              {t('careers.openings.location')}
              <h6>{offer.location.split(',')[0]}</h6>
            </Styles.Location>
          </Styles.OffersContainerMob>
        ))}
      </Styles.RolesMobile>
    </>
  );
};

const Offers = () => {
  const { t, i18n } = useTranslation();
  const [offers, setOffers] = useState<Offer[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredTeam, setFilteredTeam] = useState('');
  const [filteredLocation, setFilteredLocation] = useState([]);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    const getOffersData = async () => {
      const sheetToUse = getGoogleSheetsEnv(i18n.language.toLowerCase());
      try {
        const data = await fetchGoogleSheetsData({
          apiKey: process.env.GATSBY_GOOGLE_API_KEY || '',
          sheetId: sheetToUse || '',
        });
        setOffers(data[0].data as Offer[]);
        setLoading(false);
      } catch (error: any) {
        setError(error);
        setLoading(false);
      }
    };
    getOffersData();
  }, [i18n.language]);

  const searchOffer = (offers: any[], searchTerm: any) => {
    return offers.filter((offer) =>
      offer.roleTitle.toUpperCase().includes(searchTerm.toUpperCase()),
    );
  };

  const filterByTeam = (offers: any[], selectedTeam: any) => {
    if (!selectedTeam) return offers;
    return offers.filter(
      (offers) => offers.team.toUpperCase() === selectedTeam.toUpperCase(),
    );
  };

  const filterByLocation = (offers: any[], selectedLocation: any) => {
    if (selectedLocation.length === 0) return offers;
    return offers.filter((offer) => selectedLocation.includes(offer.location));
  };

  const filteredOffers = filterByLocation(
    filterByTeam(searchOffer(offers, searchTerm), filteredTeam),
    filteredLocation,
  );

  const teams = offers.map((offer) => offer.team);

  const uniqueTeams = [...new Set(teams)];

  const locations = offers.map((offer) => offer.location);

  const uniqueLocations = [...new Set(locations)];

  const hideSearchAndFilter =
    (filteredOffers.length === 0 &&
      (filteredLocation.length > 0 ||
        filteredTeam.length > 0 ||
        searchTerm.length > 0)) ||
    filteredOffers.length > 0;

  const toggleModal = () => {
    setModal(!modal);
  };

  const handleRemoveLocationFilter = (location: string) => {
    setFilteredLocation(
      filteredLocation.filter((chipsLocation) => chipsLocation !== location),
    );
  };

  return (
    <Styles.SectionWrapper>
      <Styles.ComponentHeading>
        <h6> {t('careers.open_positions.title')} </h6>
        <h3> {t('careers.open_positions.subtitle')} </h3>
        <p>
          <Trans i18nKey="careers.open_positions.description" />
        </p>
      </Styles.ComponentHeading>
      {hideSearchAndFilter && (
        <Styles.InputWrapper>
          <SearchBar onChange={setSearchTerm} />
          <span className="FilterMobile">
            <NoLinkButton invertedColors={true} onClick={toggleModal}>
              Filters
            </NoLinkButton>
            <Styles.ChipsWrapper>
              {filteredLocation.length > 0 &&
                filteredLocation.map((location) => (
                  <Styles.Chips>
                    Provincia: {location.split(',')[0]}
                    <img
                      onClick={() => handleRemoveLocationFilter(location)}
                      src={CloseChips}
                      alt=""
                      aria-hidden="true"
                    />
                  </Styles.Chips>
                ))}
            </Styles.ChipsWrapper>
            {modal && (
              <Styles.Modal>
                <div className="container">
                  <Styles.FilterHeading>
                    <img
                      src={PathFilters}
                      alt=""
                      aria-hidden="true"
                      onClick={() => toggleModal()}
                    />
                    <h4>Filters</h4>
                  </Styles.FilterHeading>
                  <span className="dropdownFilter">
                    <Dropdown
                      margin="15px 10px 12px 10px"
                      placeholder={t('careers.openings.team')}
                      dropdownOptions={uniqueTeams}
                      onChange={setFilteredTeam}
                    />
                  </span>
                  <span className="dropdownFilter">
                    <DropdownRadio
                      values={filteredLocation}
                      margin="35px 10px 20px 10px"
                      placeholder={t('careers.openings.location')}
                      dropdownOptions={uniqueLocations}
                      onChange={setFilteredLocation}
                    />
                  </span>
                  <Styles.ButtonApplyFilter>
                    <NoLinkButton invertedColors={false} onClick={toggleModal}>
                      Apply Filters
                    </NoLinkButton>
                  </Styles.ButtonApplyFilter>
                </div>
              </Styles.Modal>
            )}
          </span>
          <span className="dropdownNone">
            <Dropdown
              margin="0 8px"
              placeholder={t('careers.openings.team')}
              dropdownOptions={uniqueTeams}
              onChange={setFilteredTeam}
            />
          </span>
          <span className="dropdownNone">
            <DropdownRadio
              placeholder={t('careers.openings.location')}
              dropdownOptions={uniqueLocations}
              onChange={setFilteredLocation}
            />
          </span>
        </Styles.InputWrapper>
      )}
      <OffersList offers={filteredOffers} loading={loading} error={error} />
    </Styles.SectionWrapper>
  );
};

export default Offers;
