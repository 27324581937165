import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { rem } from 'polished';

import dropdownArrow from 'images/dropdown-arrow.png';
import redCross from 'images/red-cross.png';
import { breakpoints } from 'theme';

const Container = styled.div<{ isOpen: boolean; margin?: string }>`
  flex: 1;
  max-width: 260px;
  border: 1px solid
    ${({ theme, isOpen }) =>
      isOpen ? theme.brandPalette.primary : theme.neutralPalette.neutral3};
  border-radius: 8px;
  font-size: ${rem(16)};
  line-height: ${rem(24)};

  position: relative;
  margin: ${(props) => props.margin || '0'};

  @media (max-width: ${breakpoints.tablet}) {
    max-width: 100%;
  }
`;

const SelectedOption = styled.button<{ isFiltered: boolean }>`
  width: 100%;
  background-color: #fff;
  color: ${({ isFiltered, theme }) =>
    isFiltered ? theme.brandPalette.secondary : theme.neutralPalette.neutral6};

  border-radius: 8px;
  font-size: ${rem(16)};
  line-height: ${rem(24)};

  display: flex;
  align-items: center;

  padding: 16px;

  background-image: url(${dropdownArrow});
  background-repeat: no-repeat;
  background-position: calc(100% - 16px);
`;

const OptionsList = styled.ul<{ isOpen: boolean }>`
  list-style: none;
  padding: 16px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 1px 2px 18px rgb(0 0 0 / 25%);

  transition: all 300ms ease-in-out;

  max-height: 260px;
  overflow-y: scroll;

  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};

  width: 100%;
  position: absolute;
  z-index: 2;
  top: calc(100% + 8px);
`;

const Option = styled.li`
  text-align: left;
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  padding: 4px;
  border-radius: 6px;
  background-color: #fff;

  &:hover {
    cursor: pointer;
    background-color: #f1f1f1;
  }
`;

const ClearFilterOption = styled.button`
  text-align: left;
  color: red;
  font-size: ${rem(14)};
  line-height: ${rem(20)};
  padding: 4px 4px 4px 20px;
  border-radius: 6px;
  background-color: #fff;
  background-image: url(${redCross});
  background-repeat: no-repeat;
  background-position: center left 4px;
  background-size: 10px;

  &:hover {
    cursor: pointer;
    background-color: #f1f1f1;
  }
`;

interface Props {
  dropdownOptions: any;
  onChange: (option: string) => void;
  placeholder: string;
  margin?: string;
}

const Dropdown = ({
  dropdownOptions,
  onChange,
  placeholder,
  margin,
}: Props) => {
  const [dropdownIsOpen, setDropdownIsOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<string>(placeholder);

  const ref = useRef(null);

  const isFiltered = selectedOption !== placeholder;

  const handleDropdownClick = () =>
    setDropdownIsOpen((previousState) => !previousState);

  const handleClearFilter = () => {
    setSelectedOption(placeholder);
    setDropdownIsOpen(false);
    onChange('');
  };

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setDropdownIsOpen(false);
    }
  };

  const handleOptionClick = (option: string) => {
    setDropdownIsOpen(false);
    setSelectedOption(option);
    onChange(option);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <Container ref={ref} isOpen={dropdownIsOpen} margin={margin}>
      <SelectedOption
        isFiltered={isFiltered}
        type="button"
        onClick={handleDropdownClick}
      >
        {selectedOption}
      </SelectedOption>
      {dropdownIsOpen && (
        <div>
          <OptionsList isOpen={dropdownIsOpen}>
            {isFiltered && (
              <ClearFilterOption onClick={handleClearFilter}>
                Clear filter
              </ClearFilterOption>
            )}
            {dropdownOptions.map((option) => (
              <Option onClick={() => handleOptionClick(option)} key={option}>
                {option}
              </Option>
            ))}
          </OptionsList>
        </div>
      )}
    </Container>
  );
};

export default Dropdown;
