import styled from 'styled-components';
import { rem } from 'polished';
import { breakpoints } from 'theme';

export const SectionWrapper = styled.div`
  padding-bottom: ${rem(80)};
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    opacity: 0.7;
  }

  @media (max-width: ${breakpoints.tablet}) {
    text-align: left;
    align-items: flex-start;
    padding-bottom: ${rem(20)};
  }
`;
export const RolesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${breakpoints.tablet}) {
    display: none;
  }
`;

export const RolesMobile = styled.div`
  display: none;

  @media (max-width: ${breakpoints.tablet}) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 10px 10px 10px 0px;
  }
`;

export const ComponentHeading = styled.div`
  text-align: center;
  margin-top: ${rem(80)};
  margin-bottom: ${rem(30)};

  a {
    cursor: pointer;
  }

  h6 {
    font-size: ${rem(20)};
    font-weight: ${(props) => props.theme.fontWeights.light};
    line-height: ${rem(32)};
    color: ${(props) => props.theme.neutralPalette.neutral6};
  }

  h3 {
    font-size: ${rem(32)};
    font-weight: ${(props) => props.theme.fontWeights.semibold};
    line-height: ${rem(46)};
    margin-top: ${rem(5)};
  }

  p {
    font-weight: ${(props) => props.theme.fontWeights.regular};
    font-size: ${rem(20)};
    line-height: ${rem(32)};
    color: ${(props) => props.theme.brandPalette.dark};
    opacity: 0.8;
    margin-top: ${rem(32)};
    margin-bottom: ${rem(40)};
  }

  @media (max-width: ${breakpoints.tablet}) {
    text-align: left;
    margin: 40px 24px 24px 16px;

    h6 {
      font-size: ${rem(18)};
      line-height: ${rem(22)};
      font-weight: 300;
    }
    h3 {
      font-size: ${rem(28)};
      line-height: ${rem(32)};
      margin-top: ${rem(1)};
    }

    p {
      font-size: ${rem(20)};
      line-height: ${rem(32)};
      margin-bottom: ${rem(24)};
    }
  }
`;

export const InputWrapper = styled.div`
  width: ${rem(1050)};
  display: flex;
  flex-wrap: nowrap;

  .dropdownNone {
    width: ${rem(258)};
    height: ${rem(48)};
  }

  .FilterMobile {
    display: none;
  }

  @media (max-width: ${breakpoints.tablet}) {
    width: 90%;
    margin: 0 14px;
    display: flex;
    flex-direction: column;

    .dropdownNone {
      display: none;
    }

    .FilterMobile {
      display: block;
      margin-top: ${rem(16)};
      text-align: center;
      justify-content: center;
      border-radius: ${rem(8)};

      button {
        width: 100%;
        height: ${rem(58)};
        border: 1px solid #e9ecef;
      }
    }
  }
`;

export const ChipsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  img {
    padding-left: ${rem(10)};
  }
`;

export const Chips = styled.div`
  cursor: pointer;
  margin-right: ${rem(24)};
  padding: ${rem(10)};
  margin-top: ${rem(20)};
  background-color: ${(props) => props.theme.brandPalette.light};
  border-radius: ${rem(64)};
`;

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  height: 100%;
  width: 100%;
  z-index: 999;
`;

export const ButtonApplyFilter = styled.div`
  margin-top: ${rem(330)};
  margin-right: ${rem(24)};
  margin-left: ${rem(24)};
`;

export const FilterHeading = styled.div`
  margin-bottom: ${rem(16)};
  margin-top: ${rem(42)};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: flex-start;
  align-content: stretch;

  img {
    color: ${(props) => props.theme.brandPalette.primary};
    margin-bottom: ${rem(40)};
    cursor: pointer;
    margin-right: ${rem(137)};
    margin-left: ${rem(5)};
  }
  h4 {
    border-radius: ${rem(8)};
    font-weight: 600;
    font-size: ${rem(16)};
    line-height: ${rem(22)};
    margin-bottom: ${rem(40)};
    margin-right: ${rem(160)};
  }
`;

export const SpanListings = styled.div`
  width: 1040px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-top: ${rem(70)};
  margin-bottom: ${rem(17)};
  padding: 0 22px;
  color: ${(props) => props.theme.neutralPalette.neutral6};

  @media (max-width: ${breakpoints.tablet}) {
    display: none;
  }
`;

export const RoleSpan = styled.span`
  width: 57%;
`;
export const ProfessionSpan = styled.span`
  width: 27%;
`;
export const LocationSpan = styled.span`
  width: 35%;
`;

export const RolesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const OffersContainer = styled.div`
  width: ${rem(1050)};
  height: ${rem(64)};
  background: ${(props) => props.theme.brandPalette.light};
  border-radius: ${rem(16)};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;

  h6 {
    font-weight: ${(props) => props.theme.fontWeights.medium};
    font-size: ${rem(20)};
    line-height: ${rem(32)};
    padding: 0 22px;
  }

  .apply-link {
    cursor: pointer;
    font-weight: ${(props) => props.theme.fontWeights.medium};
    font-size: ${rem(20)};
    line-height: ${rem(32)};
    padding: 0 22px;
    color: ${(props) => props.theme.brandPalette.primary};
  }
`;

export const OffersContainerMob = styled.div`
  width: 100%;
  height: ${rem(149)};
  background: ${(props) => props.theme.brandPalette.light};
  border-radius: ${rem(8)};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 24px 24px 8px 24px;

  h6 {
    font-weight: ${(props) => props.theme.fontWeights.medium};
    font-size: ${rem(18)};
    line-height: ${rem(22)};
    text-align: start;
    margin-bottom: ${rem(20)};
  }

  .apply-link {
    cursor: pointer;
    font-weight: ${(props) => props.theme.fontWeights.medium};
    font-size: ${rem(14)};
    line-height: ${rem(21)};
    color: ${(props) => props.theme.brandPalette.primary};
  }

  @media (max-width: ${breakpoints.tablet}) {
    width: ${rem(342)};

    .apply-link {
      width: 20%;
      margin-bottom: ${rem(8)};
    }
    h6 {
      font-size: ${rem(14)};
      line-height: ${rem(21)};
      margin-bottom: ${rem(5)};
    }
    .RoleTitleMob {
      font-weight: ${(props) => props.theme.fontWeights.medium};
      font-size: ${rem(18)};
      line-height: ${rem(22)};
      margin-bottom: ${rem(34)};
    }
  }
`;

export const Role = styled.div`
  width: 40%;

  @media (max-width: ${breakpoints.tablet}) {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    align-content: stretch;
    margin: 16px 20px 0 17px;

    a {
      margin-right: ${rem(16)};
    }
  }
`;
export const Team = styled.div`
  width: 15%;

  @media (max-width: ${breakpoints.tablet}) {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    align-content: stretch;
    padding-left: ${rem(17)};
    padding-top: ${rem(2)};
    list-style-type: none;
    border-bottom: 1px solid rgba(58, 68, 89, 0.06);
    font-size: ${rem(12)};
    line-height: ${rem(18)};
    margin-bottom: ${rem(2)};

    h6 {
      margin-right: ${rem(18)};
    }
  }
`;
export const Location = styled.div`
  width: 15%;

  @media (max-width: ${breakpoints.tablet}) {
    width: 90%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    align-content: stretch;
    padding-left: ${rem(17)};
    padding-top: ${rem(5)};
    font-size: ${rem(12)};
    line-height: ${rem(18)};

    h6 {
      margin-right: -15px;
    }
  }
`;

export const ErrorAndLoading = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  margin: 80px 0;
  height: ${rem(300)};

  h1 {
    font-weight: ${(props) => props.theme.fontWeights.medium};
    font-size: ${rem(20)};
    line-height: ${rem(32)};
    padding: 0 22px;
  }
`;
