import React, { useState, useRef, useEffect, useId } from 'react';
import styled from 'styled-components';
import { rem } from 'polished';

import dropdownArrow from 'images/dropdown-arrow.png';
import { breakpoints } from 'theme';
import SearchBar from 'components/SearchBar';

import SelectedRadio from 'images/SelectedRadioOption.png';
import NonSelectedRadio from 'images/NonSelectedRadio.png';

const Container = styled.div<{ isOpen: boolean; margin?: string }>`
  flex: 1;
  max-width: 349px;
  border: 1px solid
    ${({ theme, isOpen }) =>
      isOpen ? theme.brandPalette.primary : theme.neutralPalette.neutral3};
  border-radius: 8px;
  font-size: ${rem(16)};
  line-height: ${rem(24)};

  position: relative;

  margin: ${(props) => props.margin || '0'};

  @media (max-width: ${breakpoints.tablet}) {
    max-width: 100%;
  }
`;

const SelectedOption = styled.button<{ isFiltered: boolean }>`
  width: 100%;
  background-color: #fff;
  color: ${({ isFiltered, theme }) =>
    isFiltered ? theme.brandPalette.neutral8 : theme.neutralPalette.neutral6};

  border-radius: 8px;
  font-size: ${rem(16)};
  line-height: ${rem(24)};

  display: flex;
  align-items: center;
  text-align: center;

  padding: 16px;

  background-image: url(${dropdownArrow});
  background-repeat: no-repeat;
  background-position: calc(100% - 16px);
`;

const OptionsList = styled.ul<{ isOpen: boolean }>`
  list-style: none;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 1px 2px 18px rgb(0 0 0 / 25%);

  transition: all 300ms ease-in-out;

  display: inline-block;
  text-align: left;

  max-height: 370px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 6px;
    mix-blend-mode: normal;
    border-radius: 3px;
  }

  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.neutralPalette.neutral3};
  }

  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.neutralPalette.neutral6};
  }

  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};

  width: 100%;

  z-index: 2;
  top: calc(100% + 10px);
`;

const Option = styled.li`
  width: 100%;
  height: 56px;
  padding: 6px 12px;

  font-size: ${rem(16)};
  line-height: ${rem(24)};

  background-color: #fff;
  border-bottom: 1px solid rgba(66, 66, 66, 0.1);

  &:first-of-type {
    margin-top: 10px;
    border-top: none;
    border-bottom: 1px solid rgba(66, 66, 66, 0.1);
  }
  &:last-of-type {
    border-bottom: none;
  }

  &:hover {
    cursor: pointer;
    background-color: #f1f1f1;
  }
`;

const RadioOption = styled.span`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
`;

const SearchWrapper = styled.div`
  width: 90%;
  margin: 8px 0 0 14px;
  display: flex;
  flex-wrap: nowrap;
`;

interface Props {
  dropdownOptions: any;
  onChange: (option: Array<string>) => void;
  placeholder: string;
  margin?: string;
  values: Array<string>;
}

const DropdownRadio = ({
  dropdownOptions,
  onChange,
  placeholder,
  margin,
  values = [],
}: Props) => {
  const [dropdownIsOpen, setDropdownIsOpen] = useState<boolean>(false);
  const [selectedOptions, setSelectedOptions] = useState<Set<string>>(
    new Set(values),
  );
  const [searchTerm, setSearchTerm] = useState('');

  const ref = useRef(null);

  const isFiltered = selectedOptions.size >= 1;

  const handleDropdownClick = () =>
    setDropdownIsOpen((previousState) => !previousState);

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setDropdownIsOpen(false);
    }
  };

  const handleOptionClick = (option: string) => {
    const dropdownOptions = new Set(selectedOptions);
    if (dropdownOptions.has(option)) {
      dropdownOptions.delete(option);
    } else {
      dropdownOptions.add(option);
    }
    setSelectedOptions(dropdownOptions);
    onChange([...dropdownOptions]);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const dropdrownOptionBySearchTerm = dropdownOptions.filter((option) =>
    option.toUpperCase().includes(searchTerm.toUpperCase()),
  );

  return (
    <Container ref={ref} isOpen={dropdownIsOpen} margin={margin}>
      <SelectedOption
        isFiltered={isFiltered}
        type="button"
        onClick={handleDropdownClick}
      >
        {selectedOptions.size > 1 ? placeholder : placeholder}
      </SelectedOption>
      {dropdownIsOpen && (
        <OptionsList isOpen={dropdownIsOpen}>
          <SearchWrapper>
            <SearchBar onChange={setSearchTerm} />
          </SearchWrapper>
          {dropdrownOptionBySearchTerm.map((option) => (
            <Option onClick={() => handleOptionClick(option)} key={option}>
              <RadioOption>
                {selectedOptions.has(option) ? (
                  <strong>{option.split(',')[0]}</strong>
                ) : (
                  option.split(',')[0]
                )}
                {selectedOptions.has(option) ? (
                  <img src={SelectedRadio} alt="" aria-hidden="true" />
                ) : (
                  <img src={NonSelectedRadio} alt="" aria-hidden="true" />
                )}
              </RadioOption>
            </Option>
          ))}
        </OptionsList>
      )}
    </Container>
  );
};

export default DropdownRadio;
