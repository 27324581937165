import React from 'react';
import styled from 'styled-components';

import searchIcon from 'images/search-icon.png';
import { rem } from 'polished';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const Input = styled.input`
  padding: 16px 16px 16px 47px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.neutralPalette.neutral3};
  color: ${(props) => props.theme.brandPalette.dark};

  font-size: ${rem(16)};
  line-height: ${rem(24)};

  background-image: url(${searchIcon});
  background-repeat: no-repeat;
  background-position: center left 16px;

  flex: 1;

  &:focus {
    outline: none;
    border: 1px solid ${(props) => props.theme.brandPalette.primary};
  }

  &::placeholder {
    color: ${(props) => props.theme.neutralPalette.neutral6};
  }
`;

interface SearchBarProps {
  onChange: (e: any) => void;
}

const SearchBar = ({ onChange }: SearchBarProps) => {
  const { t } = useTranslation();

  return (
    <Input
      onChange={(e) => onChange(e.target.value)}
      type="text"
      name=""
      id=""
      placeholder={t('contacts.form.offices.search')}
    />
  );
};

export default SearchBar;
