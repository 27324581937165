import React from 'react';
import { rem } from 'polished';
import styled from 'styled-components';
import { breakpoints } from 'theme';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';

interface Props {
  image: string;
  alt?: string;
  title: string;
  subtitle?: string;
}

const NoItemsImage = styled.img`
margin-bottom: ${rem(32)};
max-height: 250px;
max-width: 400px;
@media (max-width: ${breakpoints.tablet}) {
  width: 180px;
  heigh
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${rem(44)};
  padding: ${rem(12)};
`;

const Title = styled.span`
  font-size: ${rem(24)};
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  line-height: ${rem(34)};
  text-align: center;
  color: ${(props) => props.theme.neutralPalette.neutral6};
  margin-bottom: ${rem(12)};
`;

const SubTitleContainer = styled.div`
  width: 90%;
  text-align: center;
`;

const SubTitle = styled.span`
  font-size: ${rem(20)};
  font-weight: ${(props) => props.theme.fontWeights.regular};
  line-height: ${rem(32)};
  text-align: center;
  color: ${(props) => props.theme.neutralPalette.neutral6};
`;

const Bold = styled.span`
  font-size: ${rem(20)};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  line-height: ${rem(32)};
  text-align: center;
  color: ${(props) => props.theme.brandPalette.primary};
`;

const ListEmptyState = ({ image, alt, title, subtitle }: Props) => {
  const { t } = useTranslation();
  return (
    <Container>
      <NoItemsImage src={image} alt={alt} aria-hidden="true" />
      <Title>{t(title)}</Title>
      {subtitle && (
        <SubTitleContainer>
          <SubTitle>
            <Trans i18nKey={subtitle} components={{ b: <Bold /> }} />
          </SubTitle>
        </SubTitleContainer>
      )}
    </Container>
  );
};

export default ListEmptyState;
